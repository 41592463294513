.icons {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(rem(80px), 1fr));
    grid-gap: $half-spacer;

    &--large {
        grid-template-columns: repeat(auto-fill, minmax(rem(120px), 1fr));
    }

    &__icon {
        display: grid;
        grid-template-rows: repeat(2, 1fr);
        justify-items: center;
        grid-gap: $quarter-spacer;
        text-align: center;

        &:hover,
        &:focus {
            .icons__svg {
                fill: $lochmara;
            }
        }
    }
}
