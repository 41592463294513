// 1. px to rem
// 2. variables
// 3. colors
// 4. mixins
// 5. fonts

// ***** 1. px to rem *****
// Function for converting a px based font-size to rem.
@function rem($size) {
    @if unitless($size) {
        $size: $size * 1px;
    }

    $remSize: $size / 16px;

    //Default font size on html element is 100%, equivalent to 16px;
    @return #{$remSize}rem;
}

// ***** 2. variables *****
// container width
$container-width: rem(1440px);

// common spacer
$double-spacer: rem(48px);
$spacer: rem(24px);
$half-spacer: rem(16px);
$quarter-spacer: rem(8px);

// ***** 3. colors -- Name that color
// http://chir.ag/projects/name-that-color/ *****
// transparent colors
$transparent-white: rgba(255, 255, 255, 0.5);
$transparent: transparent;
$transparent-black: rgba(0, 0, 0, 0.75);

// black, gray, white
$white: #ffffff;
$wild-sand: #f6f6f6;
$gallery: #ededed;
$silver: #cdcdcd;
$silver-chalice: #a5a5a5;
$cod-gray: #131313;
$mine-shaft: #363636;
$gray: #8c8c8c;
$manatee: #9095a2;
$dusty-gray: #999999;
$thunder: #231f20;
$black: #000000;

// red
$crimson: #ed1c24;
$red: #ff0000;
$tall-poppy: #b02c2c;

// orange
$orange: #ff681f;
$tree-poppy: #f7931d;
$supernova: #ffc600;

// yellow
$yellow: #ffff00;

// green
$jagged-ice: #cceddc;
$green-haze: #00a651;
$green: #00ff00;

// blue
$ship-cove: #6691bc;
$hippie-blue: #6691bb;
$steel-blue: #5587bb;
$pelorous: #3fc1c9;
$lochmara: #0071bc;
$transparent-lochmara: rgba($lochmara, 0.75);
$regent-st-blue: #aac7e4;
$danube: #6096cc;
$cloud-burst: #1f2a43;
$comet: #545c70;
$spindle: #bfd5eb;
$pickled-bluewood: #2e3850;
$san-juan: #364f6b;
$link-water: #f4f7fc;
$blue: #0000ff;

// indigo
$indigo: #4f69c6;

// violet
$wild-watermelon: #fc5185;
$violet: #991199;

// brown
$brown-bramble: #592804;

// ***** 4. mixins *****

//  Set to a certain percentage
//  @include coordinates($xAxis, $yAxis, x); // $xAxis, $yAxis, (left/right)
@mixin coordinates($xAxis, $yAxis, $x: left) {
    position: absolute;
    top: $yAxis;
    @if $x == 'right' {
        right: $xAxis;
        transform: translate($xAxis, -$yAxis);
    } @else {
        left: $xAxis;
        transform: translate(-$xAxis, -$yAxis);
    }
}

// !! rework  with up above !!
// @mixin coordinates($xAxis, $yAxis, $position: absolute) {

//     position: $position;

//     @if $position == "relative" {
//         $x: unquote(($xAxis) + "rem");
//         $y: unquote(($yAxis) + "rem");

//         margin-top: $y;
//         margin-left: $x;

// 	}  @else if $position == "static" {
// 		$x: unquote(($xAxis) + "%");
//         $y: unquote(($yAxis) + "%");

//         top: $y;
//         left: $x;
//         transform: none;
// 	} @else {
//         $x: unquote(($xAxis) + "%");
//         $y: unquote(($yAxis) + "%");

//         top: $y;
//         left: $x;
//         transform: translate(-$x, -$y);
//     }
// }

// aspect ratio
// https://engageinteractive.co.uk/blog/top-10-scss-mixins
// https://ratiobuddy.com/
// @include responsive-ratio($x, $y); // $x, $y
@mixin responsive-ratio($x, $y) {
    $padding: unquote(($y / $x) * 100 + '%');

    position: relative;
    height: 0;
    width: 100%;
    padding-top: $padding;
}

// ***** 4. fonts *****
// $fontPath: '~fonts';
$fontPath: '../fonts';
$lato-family: 'Lato';
$asap-family: 'Asap';
$fallback: Helvetica, sans-serif;
$yanone-family: 'Yanone Kaffeesatz';

@mixin font($family, $weight, $style) {
    font-family: $family;
    font-weight: $weight;
    font-style: $style;
}

@mixin font-style($font-size, $line-height, $color) {
    // font-size: $font-size;
    font-size: rem($font-size);

    @if $line-height == normal {
        line-height: normal;
    } @else {
        line-height: rem($line-height);
    }

    color: $color;
}

// Convert Photoshop tracking to CSS letter-spacing -- http://blog.joshcairney.com/post/36896165521/convert-photoshop-tracking-to-css-letter-spacing
// Do not use 'px' w/ $font-size and $tracking
@mixin letter-spacing($font-size, $tracking) {
    letter-spacing: (($font-size * $tracking) / 1000) * 1px;
}

// standard web font
@mixin fallback-normal {
    @include font($fallback, normal, normal); //$family, $weight, $style
}

@mixin fallback-bold {
    @include font($fallback, bold, normal); //$family, $weight, $style
}

@mixin fallback-italic {
    @include font($fallback, normal, italic); //$family, $weight, $style
}

@mixin fallback-bold-italic {
    @include font($fallback, bold, italic); //$family, $weight, $style
}

// yanone-kaffeesatz - regular
// @mixin yanone {
//    @include font($yanone-family, 400, normal); //$family, $weight, $style
// }

// @font-face {
//    @include yanone;
//    src: local(''),
//       url('#{$fontPath}/yanone-kaffeesatz-v14-latin-regular.woff2')
//          format('woff2');
//    font-display: swap;
// }

// asap - regular
@mixin asap {
    @include font($asap-family, 400, normal); //$family, $weight, $style
}

@font-face {
    @include asap;
    src: url('#{$fontPath}/asap-v30-latin-regular.woff2') format('woff2'),
        local('Asap Regular'), local('Asap-Regular');
    font-display: swap;
}

// font-pie - fallback
@font-face {
    font-family: 'asap-fallback';
    font-style: normal;
    font-weight: 400;
    size-adjust: 100.07%;
    ascent-override: 93.33%;
    descent-override: 21.18%;
    line-gap-override: 0%;
    src: local('Arial');
}

// lato - regular
@mixin lato {
    @include font($lato-family, 400, normal); //$family, $weight, $style
}

@font-face {
    @include lato;
    src: local('Lato Regular'), local('Lato-Regular'),
        url('#{$fontPath}/lato-v16-latin-regular.woff2') format('woff2');
    font-display: swap;
}

// font-pie - fallback
@font-face {
    font-family: 'lato-fallback';
    font-style: normal;
    font-weight: 400;
    size-adjust: 97.84%;
    ascent-override: 100.88%;
    descent-override: 21.77%;
    line-gap-override: 0%;
    src: local('Arial');
}

// // lato - italic
// @mixin lato-italic {
//    @include font($lato-family, 400, italic); //$family, $weight, $style
// }

// @font-face {
//    @include lato-italic;
//    src: local('Lato Italic'), local('Lato-Italic'),
//       url('#{$fontPath}/lato-v16-latin-italic.woff2') format('woff2');
//    font-display: swap;
// }

// // lato - 700
// @mixin lato-700 {
//    @include font($lato-family, 700, normal); //$family, $weight, $style
// }

// @font-face {
//    @include lato-700;
//    src: local('Lato Bold'), local('Lato-Bold'),
//       url('#{$fontPath}/lato-v16-latin-700.woff2') format('woff2');
//    font-display: swap;
// }

// // lato -700 italic
// @mixin lato-700-italic {
//    @include font($lato-family, 700, italic); //$family, $weight, $style
// }

// @font-face {
//    @include lato-700-italic;
//    src: local('Lato Bold Italic'), local('Lato-BoldItalic'),
//       url('#{$fontPath}/lato-v16-latin-700italic.woff2') format('woff2');
//    font-display: swap;
// }
