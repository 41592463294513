.home-page {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: $spacer;
    grid-template-areas:
        'today          .'
        'my-profile     social'
        'welcome        perf'
        'education      work-history'
        'footer         back-to-top';
    padding: $spacer;
    background: $silver;

    // $max-xlarge
    @include breakpoint($max-xlarge) {
        grid-template-areas:
            'today              today'
            'my-profile         my-profile'
            'welcome            welcome'
            'social             social'
            'perf               perf'
            'education          education'
            'work-history       work-history'
            'footer             footer'
            'back-to-top        back-to-top';
    }
}
