.social {
    grid-area: social;
    display: grid;

    &__section {
        overflow: hidden;
    }

    &__icons {
        margin-top: $half-spacer;
    }

    &__icon {
        @include breakpoint($min-xlarge) {
            animation-name: bounce-in;
            animation-duration: 2s;
            animation-iteration-count: 1;
            animation-timing-function: ease-out;
            animation-delay: calc(var(--animation-delay) * 100ms);
        }

        &:hover,
        &:focus {
            .social__svg {
                fill: $lochmara;
            }

            .social__image {
                background: $lochmara;
                mix-blend-mode: difference;
            }
        }
    }

    &__svg {
        align-self: end;
    }

    // &__svg,
    // &__image {
    //    align-self: end;
    // }

    // could not find a diigo svg
    // &__image {
    //    width: rem(32px);
    //    height: rem(32px);
    //    @extend .image--border-radius;
    //    overflow: hidden;
    // }
}
