.back-to-top {
   padding: $half-spacer;
   background: $white;
   @extend .u-outline-styles;

   &:hover {
      outline-color: $lochmara;
   }

   &__svg {
      animation-name: tada;
      animation-duration: 3s;
      animation-fill-mode: both;
      animation-iteration-count: infinite;
   }

   &:hover,
   &:focus {
      .back-to-top__svg {
         fill: $lochmara;
      }
   }
}
