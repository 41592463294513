.svg {
   display: inline-block;
   stroke-width: 0;
   stroke: currentColor;
   fill: currentColor;
   vertical-align: middle;

   // map of svg fill colors
   $map-svg-colors: (
      'white': $white,
      'red': $red,
      'tall-poppy': $tall-poppy,
      'pelorous': $pelorous,
      'lochmara': $lochmara,
      'black': $black,
   );

   // svg colors
   @each $name, $fill in $map-svg-colors {
      &--#{$name} {
         fill: $fill;
      }
   }

   // map of svg sizes
   $map-svg-sizes: (
      'xsmall': rem(14px),
      'small': rem(18px),
      'medium': rem(24px),
      'large': rem(28px),
      'xlarge': rem(32px),
      'xxlarge': rem(48px),
      'xxxlarge': rem(100px),
   );

   // svg sizes
   @each $name, $size in $map-svg-sizes {
      &--#{$name} {
         width: $size;
         height: $size;
      }
   }
}
