.my-profile {
    grid-area: my-profile;
    display: grid;

    &__section {
        display: grid;
        grid-template-columns: rem(100px) 1fr;
        grid-gap: $half-spacer;

        // small
        @include breakpoint($small) {
            grid-template-columns: 1fr;
        }
    }

    &__figure {
        position: relative;

        // small
        @include breakpoint($small) {
            text-align: center;
        }
    }

    &__details {
        // small
        @include breakpoint($small) {
            display: flex;
            flex-direction: column;
            text-align: center;
        }
    }

    &__image {
        @extend .grayscale-10;
        transition: all ease-in-out 0.5s;

        &:hover,
        &:focus {
            border-radius: rem(8px);
        }

        &:hover {
            @extend .hover-grayscale-0;
        }

        &:focus {
            @extend .grayscale-0;
            transition: all ease-in-out 0s;
            outline: rem(4px) solid $lochmara;
        }
    }

    &__figcaption {
        $bubble-height: rem(100px);

        position: absolute;
        top: -$bubble-height;
        left: calc(#{$bubble-height} / 2);
        width: 100%;
        height: $bubble-height;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        z-index: 99;
    }

    &__svg {
        stroke-width: rem(1px);
        stroke: $cod-gray;
    }

    &__svg-text {
        stroke-width: rem(0);
        stroke: transparent;
        fill: $cod-gray;
    }

    &__header {
        margin-bottom: $quarter-spacer;
    }
}
