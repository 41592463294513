// Reference CSS animations sites:
// https://animate.style/
// https://animista.net/

// bounce in
@keyframes bounce-in {
   0% {
      transform: translateY(rem(-500px));
      opacity: 0;
   }

   70% {
      transform: translateY(rem(150px));
      opacity: 1;
   }

   100% {
      transform: translateY(0);
   }
}

// bounce in - left
@keyframes bounceInLeft {
   from,
   60%,
   75%,
   90%,
   to {
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
   }

   0% {
      opacity: 0;
      transform: translate3d(rem(-3000px), 0, 0);
   }

   60% {
      opacity: 0.0125;
      transform: translate3d(rem(25px), 0, 0);
   }

   75% {
      opacity: 0.5;
      transform: translate3d(rem(-10px), 0, 0);
   }

   90% {
      opacity: 1;
      transform: translate3d(rem(5px), 0, 0);
   }

   to {
      transform: translate3d(0, 0, 0);
   }
}

@keyframes bounceInUp {
   from,
   60%,
   75%,
   90%,
   to {
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
   }

   from {
      opacity: 0;
      transform: translate3d(0, 3000px, 0) scaleY(5);
   }

   60% {
      opacity: 1;
      transform: translate3d(0, -20px, 0) scaleY(0.9);
   }

   75% {
      transform: translate3d(0, 10px, 0) scaleY(0.95);
   }

   90% {
      transform: translate3d(0, -5px, 0) scaleY(0.985);
   }

   to {
      transform: translate3d(0, 0, 0);
   }
}

// rotate in/down left
@keyframes rotateInDownLeft {
   from {
      transform-origin: left bottom;
      transform: rotate3d(0, 0, 1, -45deg);
   }

   to {
      transform-origin: left bottom;
      transform: translate3d(0, 0, 0);
   }
}

// blink
@keyframes blink {
   from {
      opacity: 1;
   }
   to {
      opacity: 0;
   }
}

// background animation
@keyframes homeBackground {
   from {
      background-position: 0 0;
   }

   to {
      background-position: rem(64px) rem(64px);
   }
}

@keyframes tada {
   from {
      transform: scale3d(1, 1, 1);
   }

   10%,
   20% {
      transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
   }

   30%,
   50%,
   70%,
   90% {
      transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
   }

   40%,
   60%,
   80% {
      transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
   }

   to {
      transform: scale3d(1, 1, 1);
   }
}
