.perf {
    grid-area: perf;
    display: grid;

    &__section {
        overflow: hidden;
    }

    &__icons {
        margin-top: $half-spacer;
    }
}
