.today {
    grid-area: today;

    &__figure {
        display: grid;
        text-align: center;
    }

    &__icon {
        display: block;
        justify-self: center;
        margin-bottom: $quarter-spacer;
    }

    &__time {
        text-transform: capitalize;
    }
}
