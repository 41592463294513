$svg-size: 24;

.details {
   display: block;
   margin-bottom: $quarter-spacer;

   &:last-of-type {
      margin-bottom: 0;
   }

   // open
   &[open] {
      .details__summary {
         // &:before {
         //    content: '';
         //    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='$svg-size' height='$svg-size' viewBox='0 0 32 32'%3E%3Ctitle%3Eminus%3C/title%3E%3Cpath d='M24.1 14.4q0.96 0 0.96 1.6t-0.96 1.6h-16.64q-0.96 0-0.96-1.6t0.96-1.6h16.64z'%3E%3C/path%3E%3C/svg%3E")
         //       no-repeat center center;
         //    // filter: invert(54%) sepia(38%) saturate(5568%) hue-rotate(312deg)
         //    //    brightness(99%) contrast(99%);
         // }
      }
   }

   &__summary {
      position: relative;
      margin-bottom: $half-spacer;
      padding-right: $double-spacer;

      // default marker
      // &::-webkit-details-marker {
      //    display: none;
      //    color: transparent;
      //    // background: red;
      // }

      // &:before {
      //    content: '';
      //    @include coordinates(
      //       0%,
      //       50%,
      //       right
      //    ); // $xAxis, $yAxis, (left or right)
      //    width: rem($svg-size);
      //    height: rem($svg-size);
      //    transition: all 0.3s ease-out;
      //    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='$svg-size' height='$svg-size' viewBox='0 0 32 32'%3E%3Ctitle%3Eplus%3C/title%3E%3Cpath d='M24.1 14.4q0.96 0 0.96 1.6t-0.96 1.6h-6.72v6.72q0 0.96-1.6 0.96t-1.6-0.96v-6.72h-6.72q-0.96 0-0.96-1.6t0.96-1.6h6.72v-6.72q0-0.96 1.6-0.96t1.6 0.96v6.72h6.72z'%3E%3C/path%3E%3C/svg%3E")
      //       no-repeat center center;
      // }

      &:focus {
         // outline: rem(2px) solid $black;
         color: $lochmara;
         outline: rem(2px) solid $wild-watermelon;
         outline-offset: rem(2px);
      }
   }

   //  title
   &__title {
      margin-bottom: $quarter-spacer;
   }

   // contents
   &__content {
      margin-bottom: $quarter-spacer;
   }
}

// Firefox
// html[data-useragent*='Firefox'] {
//    .details {
//       // open
//       &[open] {
//          .details__summary {
//             &:before {
//                content: '';
//                background: transparent;
//                display: none;
//             }
//          }
//       }

//       &__summary {
//          position: relative;
//          padding-right: $double-spacer;

//          &:before {
//             content: '';
//             background: transparent;
//             display: none;
//          }
//       }

//       //  title
//       &__title {
//          display: inline-block;
//       }
//    }
// }
