.section {
   padding: $half-spacer;
   // background: $gallery;
   background: $white;
   @extend .u-outline-styles;

   &--border-bottom {
      outline: 0;
      border-bottom: rem(12px) double $mine-shaft;
   }

   &__title {
      padding-bottom: $quarter-spacer;
   }
}
