.work-history {
   grid-area: work-history;
   display: grid;
   row-gap: $spacer;
   grid-template-columns: 1fr;

   &__list {
      margin-left: $half-spacer;
   }

   .work-history-interests {
      li {
         display: flex;
      }

      svg {
         align-self: center;
      }
   }
}
