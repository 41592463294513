// list item - wrapper
// example 1: div.list-item > svg | link
// example 2: a.list-item > svg | link
// example 3: button.list-item > svg | link

.list-item {
   float: left;
   display: inline;
   clear: both;
   margin-bottom: $quarter-spacer;

   // No matter order, first-child will have same margin-right
   &__svg,
   &__copy {
      &:first-child {
         margin-right: $quarter-spacer;
      }
   }

   &:hover,
   &:focus {
      //   .list-item__copy {
      //   }

      .list-item__svg {
         fill: $lochmara;
      }
   }
}
