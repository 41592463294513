// skills
.skills {
   display: grid;
   grid-template-columns: repeat(2, 1fr);
   grid-template-rows: auto 1fr;
   column-gap: $half-spacer;
   row-gap: $spacer;
   grid-template-areas:
      'skills-title       skills-legend'
      'skills-accordion   skills-accordion';

   &__title {
      grid-area: skills-title;
   }

   &__legend {
      grid-area: skills-legend;
      padding: rem(10px) rem(6px);
      // background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='20' viewBox='0 0 5 10'%3E%3Crect width='110%25' x='-5%25' y='-5%25' height='110%25' fill='%23fff'/%3E%3Cline x1='-2' y1='1' x2='7' y2='10' stroke='%23eee' stroke-width='2'/%3E%3Cline x1='-2' y1='6' x2='7' y2='15' stroke='%23eee' stroke-width='2'/%3E%3Cline x1='-2' y1='-4' x2='7' y2='5' stroke='%23eee' stroke-width='2'/%3E%3Cline x1='7' y1='1' x2='-2' y2='10' stroke='%23f5f5f5' stroke-width='2'/%3E%3Cline x1='7' y1='6' x2='-2' y2='15' stroke='%23f5f5f5' stroke-width='2'/%3E%3Cline x1='7' y1='-4' x2='-2' y2='5' stroke='%23f5f5f5' stroke-width='2'/%3E%3C/svg%3E")
      //    center center;
      outline: rem(8px) double $thunder;
      // outline-offset: rem(4px);
   }

   &__legend-content {
      display: grid;
      grid-template-columns: auto 1fr;
      align-items: center;
      grid-gap: $quarter-spacer;
   }

   &__accordion {
      grid-area: skills-accordion;
   }
}
