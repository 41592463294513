.home-back-to-top {
   grid-area: back-to-top;

   &__section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
   }
}
