.u {
   &-is-visible {
      display: block;
      visibility: visible;
   }

   &-is-not-visible {
      visibility: hidden;
   }

   &-is-hidden {
      display: none;
      visibility: hidden;
   }

   // hide content - screen reader only
   // https://zellwk.com/blog/hide-content-accessibly/
   &-sr-only {
      position: absolute;
      width: rem(1px);
      height: auto; /* new - was 1px */
      margin: 0; /* new - was -1px */
      padding: 0;
      border: 0;
      clip: rect(0 0 0 0);
      overflow: hidden;
      white-space: nowrap; /* 1 */
   }

   // button is disabled and you can not click
   &-disabled {
      pointer-events: none;
      cursor: default;
   }

   // button is enabled and you can click
   &-is-clickable {
      pointer-events: auto;
      cursor: pointer;
      cursor: hand;
   }

   // text-decoration
   &-no-underline,
   &-underline {
      font-weight: 700;
   }

   &-no-underline {
      text-decoration: none;

      &:hover,
      &:focus {
         text-decoration: underline;
      }
   }

   &-underline {
      text-decoration: underline;

      &:hover,
      &:focus {
         text-decoration: none;
      }
   }

   // link - hover/focus styles
   &-link-hover-focus {
      color: $lochmara;
      text-decoration-color: $lochmara;
      text-decoration-thickness: rem(2px);
      text-underline-offset: rem(2px);
   }

   // section and back to top - outline styles
   &-outline-styles {
      outline: rem(4px) solid $cod-gray;
      outline-offset: rem(4px);
   }

   // // equal height
   // &-equal-height {
   //     display: flex;
   //     flex-wrap: wrap;
   //     justify-content: flex-start;

   //     &__column {
   //         flex-grow: 0;
   //         flex-shrink: 1;
   //     }

   //     img {
   //         width: 100%;
   //         height: 100%;
   //         object-fit: cover;
   //     }
   // }

   // &-full-width {
   //     width: 100%;
   // }
}
