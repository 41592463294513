.skip-link {
   position: absolute;
   top: 0;
   right: $spacer;
   padding: $half-spacer;
   color: $white;
   background: $lochmara;
   outline-offset: rem(4px);
   transform: translateY(-100%);
   transition: all ease-in-out 0.5s;
   will-change: transform;

   &:focus {
      color: $white;
      background: $lochmara;
      top: $spacer;
      outline: rem(4px) solid $lochmara;
      transform: translateY(0%);
      z-index: 999;

      &:hover {
         color: $lochmara;
         background: $white;
      }
   }
}
